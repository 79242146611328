export function Modal({ show, children }) {
  return (
    <div
      className={`${
        !show && "hidden"
      } fixed h-full w-full top-0 left-0 bg-[rgba(138,148,153,0.7)] bg-opacity-80 flex items-start justify-center z-30 overflow-y-scroll overflow-x-hidden detail-modal`}
    >
      <div className="relative mx-auto rounded-lg bg-white my-[50px] -md:w-[96%] md:w-[400px]">
        <div className="p-4">{children}</div>
      </div>
    </div>
  );
}
