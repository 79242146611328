import { capitalize } from "./str";

export function setInsuranceNameFromRow(row) {
    if (!row.insurance) return "";

    if (row.insurance.toLowerCase() === "privat") {
        return "PRIVAT";
    }

    return capitalize(row.insurance);
}